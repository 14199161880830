import React from 'react';

const Contact = () => {
    return (
        <>
            {/* hero-section */}
            <section className="hero-section about gap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-12" data-aos="fade-up"  data-aos-delay="200" data-aos-duration="300">
                            <div className="about-text">
                                <ul className="crumbs d-flex">
                                    <li><a href="index.html">Home</a></li>
                                    <li className="two"><a href="index.html"><i className="fa-solid fa-right-long"></i>Contact Us</a></li>
                                </ul>
                                <h2>Get in Touch with Delivas</h2>
                                <p>We're here to help you with your orders, queries, or feedback. Whether you're a customer or a partner, feel free to reach out to us anytime. Our team is always ready to assist you.</p>
                            </div>
                            <div className="row">
                                {/* <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="address">
                                        <i className="fa-solid fa-location-dot"></i>
                                        <h5>1717 Harrison St, San Francisco, CA 94103, USA</h5>
                                    </div>
                                </div> */}
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="address">
                                        <i className="fa-solid fa-envelope"></i>
                                        <a href="mailto:support@delivas.com"><h6>support@delivas.com</h6></a>
                                        <span>For general inquiries</span>
                                        <a href="mailto:partners@delivas.com"><h6>info@delivas.com</h6></a>
                                        <span>For partnership opportunities</span>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="address">
                                        <i className="fa-solid fa-phone"></i>
                                        <a href="callto:+27794185925"><h6>+27 79 418 5925</h6></a>
                                        <span>Customer Service</span>
                                        <a href="callto:+27794185925"><h6>+27 79 418 5925</h6></a>
                                        <span>Business Inquiries</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12" data-aos="fade-up"  data-aos-delay="300" data-aos-duration="400">
                            <div className="contact-us-img">
                                <img alt="Contact Us - Delivas" src="./assets/img/contacts-1.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* contact form and map */}
            <section className="gap no-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" data-aos="fade-up"  data-aos-delay="300" data-aos-duration="400">
                            <div className="contact-map-data">
                                <div className="join-courier content">
                                    <h3>Get in touch with us</h3>
                                    <p>For any queries, suggestions, feedback or compliments, please get in touch with us using the form below</p>
                                    <form className="blog-form">
                                        <div className="name-form">
                                            <i className="fa-regular fa-user"></i>
                                            <input type="text" name="name" placeholder="Enter your name" />
                                        </div>
                                        <div className="name-form">
                                            <i className="fa-regular fa-envelope"></i>
                                            <input type="text" name="email" placeholder="Enter your email" />
                                        </div>
                                        <textarea placeholder="Enter your message"></textarea>
                                        <button className="button-price">Submit</button>
                                    </form>
                                </div>
                                {/* <div className="contact-map">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689446.104646556!2d28.705460424349365!3d48.83127549941125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d1d9c154700e8f%3A0x1068488f64010!2sUkraine!5e0!3m2!1sen!2s!4v1661009847728!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;
