import React from 'react';

const Footer = () => {
    return (
        <footer className="gap no-bottom" style={{backgroundColor: "#363636"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                        <div className="footer-description">
                            <a href="index.html">
                                <svg xmlns="http://www.w3.org/2000/svg" width="163" height="38" viewBox="0 0 163 38">
                                    <g id="Logo" transform="translate(-260 -51)">
                                        <g id="Logo-2" data-name="Logo" transform="translate(260 51)">
                                            <g id="Elements">
                                                <path id="Path_1429" data-name="Path 1429"
                                                    d="M315.086,140.507H275.222v-.894c0-11.325,8.941-20.538,19.933-20.538s19.931,9.213,19.931,20.538Z"
                                                    transform="translate(-270.155 -115.396)" fill="#f29f05" />
                                                <path id="Path_1430" data-name="Path 1430"
                                                    d="M301.13,133.517a1.488,1.488,0,0,1-1.394-.994,11.361,11.361,0,0,0-10.583-7.54,1.528,1.528,0,0,1,0-3.055,14.353,14.353,0,0,1,13.37,9.527,1.541,1.541,0,0,1-.875,1.966A1.444,1.444,0,0,1,301.13,133.517Z"
                                                    transform="translate(-264.176 -113.935)" fill="#fff" />
                                                <path id="Path_1431" data-name="Path 1431"
                                                    d="M297.343,146.544a14.043,14.043,0,0,1-13.837-14.211h2.975a10.865,10.865,0,1,0,21.723,0h2.975A14.043,14.043,0,0,1,297.343,146.544Z"
                                                    transform="translate(-266.247 -108.544)" fill="#fff" />
                                                <path id="Path_1432" data-name="Path 1432"
                                                    d="M302.183,132.519a7.064,7.064,0,1,1-14.122,0Z"
                                                    transform="translate(-264.027 -108.446)" fill="#fff" />
                                                <path id="Path_1433" data-name="Path 1433"
                                                    d="M320.332,134.575H273.3a1.528,1.528,0,0,1,0-3.055h47.033a1.528,1.528,0,0,1,0,3.055Z"
                                                    transform="translate(-271.815 -108.923)" fill="#f29f05" />
                                                <path id="Path_1434" data-name="Path 1434"
                                                    d="M289.154,123.4a1.507,1.507,0,0,1-1.487-1.528v-3.678a1.488,1.488,0,1,1,2.975,0v3.678A1.508,1.508,0,0,1,289.154,123.4Z"
                                                    transform="translate(-264.154 -116.667)" fill="#f29f05" />
                                                <path id="Path_1435" data-name="Path 1435"
                                                    d="M284.777,138.133H275.3a1.528,1.528,0,0,1,0-3.055h9.474a1.528,1.528,0,0,1,0,3.055Z"
                                                    transform="translate(-270.84 -107.068)" fill="#fff" />
                                                <path id="Path_1436" data-name="Path 1436"
                                                    d="M284.8,141.691h-6.5a1.528,1.528,0,0,1,0-3.055h6.5a1.528,1.528,0,0,1,0,3.055Z"
                                                    transform="translate(-269.379 -105.218)" fill="#fff" />
                                            </g>
                                        </g>
                                        <text id="Delivas" transform="translate(320 77)" fill="#fff" fontSize="20"
                                            fontFamily="Poppins" fontWeight="700">
                                            <tspan y="0" fill="#f29f05">Delivas</tspan>
                                        </text>
                                    </g>
                                </svg>
                            </a>
                            <h2>The Best Restaurants in Your Home</h2>
                            {/* <p>Vitae congue mauris rhoncus aenean. Enim nulla</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="menu">
                            <h4>Menu</h4>
                            <ul className="footer-menu">
                                <li><a href="/">home<i className="fa-solid fa-arrow-right"></i></a></li>
                                <li><a href="/about">about us<i className="fa-solid fa-arrow-right"></i></a></li>
                                <li><a href="/restaurants">Restaurants<i className="fa-solid fa-arrow-right"></i></a></li>
                                <li><a href="/join-us">Join Us<i className="fa-solid fa-arrow-right"></i></a></li>
                                <li><a href="/contact-us">Contacts<i className="fa-solid fa-arrow-right"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="menu contacts">
                            <h4>Contacts</h4>
                            <div className="footer-location">
                                <i className="fa-solid fa-location-dot"></i>
                                <p>www.delivas.co.za</p>
                            </div>
                            <a href="mailto:info@delivas.co.za"><i className="fa-solid fa-envelope"></i>info@delivas.co.za</a>
                            <a href="callto:+27794185925"><i className="fa-solid fa-phone"></i>+27 79 418 5925</a>
                        </div>
                        <ul className="social-media">
                            <li><a href="#"><i className="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                            <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-two gap no-bottom">
                    <p>Copyright © {new Date().getFullYear()}. Delivas. All rights reserved.</p>
                    <div className="privacy">
                        <a href="/privacy-policy">Privacy Policy</a>
                        <a href="/terms-and-conditions">Terms & Services</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
