import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <>
            {/* hero-section  */}
            <section className="hero-section gap" style={{backgroundImage: "url(assets/img/background-1.png)"}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200" data-aos-duration="300">
                            <div className="restaurant">
                                <h1>The Best restaurants in your home</h1>
                                <p>Get a taste of the best food from your nearest food outlets to your doorstep</p>
                                <div className="nice-select-one">
                                    {/* <select className="nice-select Advice">
                                        <option>Choose a Restaurant</option>
                                        <option>Choose a Restaurant 1</option>
                                        <option>Choose a Restaurant 2</option>
                                        <option>Choose a Restaurant 3</option>
                                        <option>Choose a Restaurant 4</option>
                                    </select> */}
                                    <a href="#" className="button button-2">Order Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300" data-aos-duration="400">
                            <div className="img-restaurant">
                                <img alt="man" src="./assets/img/photo-1.png" />
                                {/* <div className="wilmington">
                                    <img alt="img" src="https://via.placeholder.com/90x90" />
                                    <div>
                                        <p>Restaurant of the Month</p>
                                        <h6>The Wilmington</h6>
                                        <div>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-regular fa-star-half-stroke"></i>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="wilmington location-restaurant">
                                    <i className="fa-solid fa-location-dot"></i>
                                    <div>
                                        <h6>12 Restaurant</h6>
                                        <p>In Your Location</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            {/* how-it-works-section */}
            <section className="works-section gap no-top">
                <div className="container">
                    <div className="hading" data-aos="fade-up"  data-aos-delay="200" data-aos-duration="300">
                        <h2>How It Works</h2>
                        <p>Ordering your favorite meals is easy! Just follow these simple steps to get delicious food delivered to your door.</p>
                    </div>
                    <div className="row ">
                        <div className="col-lg-4 col-md-6 col-sm-12" data-aos="flip-up" data-aos-delay="200" data-aos-duration="300">
                            <div className="work-card">
                                <img alt="img" src="./assets/img/illustration-1.png" />
                                <h4><span>01</span> Choose a Restaurant</h4>
                                <p>Browse a wide selection of local and popular restaurants in your area. Find the perfect spot to satisfy your cravings.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12" data-aos="flip-up" data-aos-delay="300" data-aos-duration="400">
                            <div className="work-card">
                                <img alt="img" src="./assets/img/illustration-2.png" />
                                <h4><span>02</span> Select Your Meal</h4>
                                <p>Explore the menu and pick your favorite dishes. Whether it's a quick bite or a full meal, we've got you covered.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12" data-aos="flip-up" data-aos-delay="400" data-aos-duration="500">
                            <div className="work-card">
                                <img alt="img" src="./assets/img/illustration-3.png" />
                                <h4><span>03</span> Enjoy Fast Delivery</h4>
                                <p>Place your order and relax! Your food will be prepared and delivered to your doorstep in no time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* your-favorite-food  */}
            <section className="your-favorite-food gap" style={{backgroundImage: "url(assets/img/background-1.png)"}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5" data-aos="fade-up" data-aos-delay="200" data-aos-duration="300">
                            <div className="food-photo-section">
                                <img alt="img" src="./assets/img/photo-3.png" />
                                <a href="#" className="one"><i className="fa-solid fa-burger"></i>Burgers</a>
                                <a href="#" className="two"><i className="fa-solid fa-cheese"></i>Steaks</a>
                                <a href="#" className="three"><i className="fa-solid fa-pizza-slice"></i>Pizza</a>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1" data-aos="fade-up" data-aos-delay="300" data-aos-duration="400">
                            <div className="food-content-section">
                                <h2>Your Favorite Meals, Delivered to Your Door</h2>
                                <p>Craving something special? Choose from a variety of delicious meals from your favorite local restaurants and have them delivered straight to your table, fresh and hot.</p>
                                <a href="#" className="button button-2">Order Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* join-partnership */}
            <section className="join-partnership gap" style={{backgroundColor: "#363636"}}>
                <div className="container">
                    <h2>Want to Join Partnership?</h2>
                    <div className="row">
                        <div className="col-lg-6" data-aos="flip-up" data-aos-delay="200" data-aos-duration="300">
                            <div className="join-img">
                                <img alt="img" src="./assets/img/photo-6.jpg" />
                                <div className="Join-courier">
                                    <h3>Join Courier</h3>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.licious96.delivasdriver&hl=en" className="button button-2">Join now <i className="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="flip-up" data-aos-delay="300" data-aos-duration="400">
                            <div className="join-img">
                                <img alt="img" src="./assets/img/photo-7.jpg" />
                                <div className="Join-courier">
                                    <h3>Join Merchant</h3>
                                    <Link to="/join-us" className="button button-2">Join now<i className="fa-solid fa-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* subscribe-section */}
            <section className="subscribe-section about gap" style={{backgroundImage: "url(assets/img/background-img.jpg)"}} >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="get-the-menu">
                                <h2 data-aos="fade-up"  data-aos-delay="200" data-aos-duration="300">Get the menu of your favorite restaurants every day</h2>
                                <form data-aos="fade-up"  data-aos-delay="300" data-aos-duration="400">
                                    <i className="fa-regular fa-bell"></i>
                                    <input type="text" name="email" placeholder="Enter email address" />
                                    <button className="button button-2">Subscribe</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
