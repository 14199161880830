import { createContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
export const AppContext = createContext();

const AppState = ({ children }) => {
    
    //const apiUrl = "http://127.0.0.1:8000/api"
    //const picUrl = "http://127.0.0.1:8000/uploads"
    const apiUrl = "https://api.delivas.co.za/api"
    const picUrl = "https://api.delivas.co.za/uploads"
   
    const values = {
        picUrl, apiUrl,
    }
    return (
        <AppContext.Provider value={values}>
            {children}
        </AppContext.Provider>
    );
}

export default AppState;