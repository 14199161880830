import React from 'react';
import { Link } from 'react-router-dom';

const Restaturants = () => {
    return (
        <>
            {/* hero-section */}
            <section className="hero-section about gap">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6" data-aos="fade-up"  data-aos-delay="200" data-aos-duration="300">
							<div className="about-text">
								<ul className="crumbs d-flex">
									<li><Link to={'/home'}>Home</Link></li>
									<li className="two"><a href=""><i className="fa-solid fa-right-long"></i>Restaurants</a></li>
								</ul>
								<h2>Restaurants</h2>
								<p>Egestas sed tempus urna et pharetra pharetra massa. Fermentum posuere urna nec tincidunt praesent semper.</p>
							</div>
						</div>
						<div className="col-lg-6" data-aos="fade-up"  data-aos-delay="300" data-aos-duration="400">
							<div className="restaurants-girl-img food-photo-section">
								<img alt="man" src="assets/img/photo-11.png" />
								<a href="#" className="one"><i className="fa-solid fa-burger"></i>Burgers</a>
								<a href="#" className="two"><i className="fa-solid fa-drumstick-bite"></i>Chicken</a>
								<a href="#" className="three"><i className="fa-solid fa-cheese"></i>Steaks</a>
								<a href="#" className="for"><i className="fa-solid fa-pizza-slice"></i>Fish</a>
							</div>
						</div>
					</div>
				</div>
	        </section>
            {/* all-restaurants  */}
            <section className="best-restaurants">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6" data-aos="flip-up"  data-aos-delay="200" data-aos-duration="300">
                            <div className="logos-card restaurant-page ">
                                <img alt="logo" src="https://via.placeholder.com/100x100" />
                                    <div className="cafa">
                                        <h4><a href="#">Kennington Lane Cafe</a></h4>
                                        <div>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-regular fa-star"></i>
                                        </div>
                                        <div className="cafa-button">
                                                <a href="#">american</a>
                                                <a href="#">steakhouse</a>
                                                <a className="end" href="#">seafood</a>
                                        </div>
                                        <p>Non enim praesent elementum facilisis leo vel fringilla. Lectus proin nibh nisl condimentum id. Quis varius quam quisque id diam vel.</p>
                                    </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="flip-up"  data-aos-delay="300" data-aos-duration="400">
                            <div className="logos-card restaurant-page">
                                <img alt="logo" src="https://via.placeholder.com/100x100" />
                                    <div className="cafa">
                                        <h4><a href="restaurant-card.html">The Wilmington</a></h4>
                                        <div>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </div>
                                        <div className="cafa-button">
                                                <a href="#">american</a>
                                                <a href="#">steakhouse</a>
                                                <a className="end" href="#">seafood</a>
                                        </div>
                                        <p>Vulputate enim nulla aliquet porttitor lacus luctus. Suscipit adipiscing bibendum est ultricies integer. Sed adipiscing diam donec adipiscing tristique.</p>
                                    </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="flip-up"  data-aos-delay="400" data-aos-duration="500">
                            <div className="logos-card restaurant-page two">
                                <img alt="logo" src="https://via.placeholder.com/100x100" />
                                    <div className="cafa">
                                        <h4><a href="restaurant-card.html">Kings Arms</a></h4>
                                        <div>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-regular fa-star-half-stroke"></i>
                                        </div>
                                        <div className="cafa-button">
                                                <a href="#">healthy</a>
                                                <a href="#">steakhouse</a>
                                                <a className="end" href="#">vegetarian</a>
                                        </div>
                                        <p>Tortor at risus viverra adipiscing at in tellus. Cras semper auctor neque vitae tempus. Dui accumsan sit amet nulla facilisi. Sed adipiscing diam .</p>
                                    </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="flip-up"  data-aos-delay="500" data-aos-duration="600">
                            <div className="logos-card restaurant-page two">
                                <img alt="logo" src="https://via.placeholder.com/100x100" />
                                    <div className="cafa">
                                        <h4><a href="restaurant-card.html">The Victoria</a></h4>
                                        <div>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-regular fa-star"></i>
                                        </div>
                                        <div className="cafa-button">
                                                <a href="#">american</a>
                                                <a href="#">steakhouse</a>
                                                <a className="end" href="#">seafood</a>
                                        </div>
                                        <p>Non enim praesent elementum facilisis leo vel fringilla. Lectus proin nibh nisl condimentum id. Quis varius quam quisque id diam vel.</p>
                                    </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="flip-up"  data-aos-delay="200" data-aos-duration="300">
                            <div className="logos-card restaurant-page two">
                                <img alt="logo" src="https://via.placeholder.com/100x100" />
                                    <div className="cafa">
                                        <h4><a href="restaurant-card.html">Lanes of London</a></h4>
                                        <div>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </div>
                                        <div className="cafa-button">
                                                <a href="#">american</a>
                                                <a href="#">steakhouse</a>
                                                <a className="end" href="#">seafood</a>
                                        </div>
                                        <p>At erat pellentesque adipiscing commodo elit at imperdiet dui. Suspendisse faucibus interdum posuere
                                        lorem. Elit sed vulputate mi sit.</p>
                                    </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="flip-up"  data-aos-delay="300" data-aos-duration="400">
                            <div className="logos-card restaurant-page two">
                                <img alt="logo" src="https://via.placeholder.com/100x100" />
                                    <div className="cafa">
                                        <h4><a href="restaurant-card.html">The Andover Arms</a></h4>

                                        <div>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-regular fa-star-half-stroke"></i>
                                        </div>
                                        <div className="cafa-button">
                                                <a href="#">healthy</a>
                                                <a href="#">steakhouse</a>
                                                <a className="end" href="#">vegetarian</a>
                                        </div>
                                        <p>Lacus vestibulum sed arcu non odio euismod lacinia at. Id neque aliquam vestibulum morbi. Ante metus dictum ullamcorper a lacus.</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Restaturants;
